import anime from 'animejs';
import { oengus } from '../classes/oengus';
import { strapi } from '../classes/strapi';
window.confetti = require('canvas-confetti');

var dayjs = require('dayjs');

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration);
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime);
require('dayjs/locale/it')


var decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    function decodeHTMLEntities(str) {
        if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
})();

let oengusRenderer = {
    totalDonationsCounter: 0,
    runnerNameShowDelay: 105000,
    runnerNameShowDuration: 2000,
    runnerSocialShowDelay: 15000,
    breakNextGameNameAlternateAnimation: undefined,
    breakNextGameCategoryAlternateAnimation: undefined,
    breakFirstShow: true,
    endCounter: 1,
    gameTabFilled: false,
    oldGoalDonationPercent: 0,
    goalDonationPercent: 0,
    goalDonationPercentCounter: 0,
    surpriseDeployed: false,
    goalDonationLeftDefault: -1192,
    goalDonationLeft: -1192,
    shownDonationGoalProgressBar: false,
    isAnimatingDonationGoalProgressBar: false,
    shouldResetDonationGoalPosition: false,
    shouldUpdateDonationGoal: false,
    breakIncentivesShowDuration: 15000,

    fizzleWatcherInterval: undefined,
    fizzling: [],
    lyrics: [
        [
            {
                text: [
                    "THIS",
                    "WAS",
                    "A",
                    "TRIUMPH"
                ],
                delayForNextRow: 3763,
            },
            {
                text: [
                    "I'M",
                    "MAKING",
                    "A NOTE",
                    "HERE"
                ],
                delayForNextRow: 2221,
            },
            {
                text: [
                    "HUGE",
                    "SUCCESS.",
                    "IT'S HARD",
                    "TO OVER-"
                ],
                delayForNextRow: 4773,
            },
            {
                text: [
                    "STATE",
                    "MY",
                    "SATIS",
                    "FACTION"
                ],
                delayForNextRow: 5238,
                function: 'fadeOutAdBreak',
            },
        ],
        [
            {
                text: [
                    "APER",
                    "TURE",
                    "SCIE",
                    "NCE"
                ],
                delayForNextRow: 3749,
                ascii: 'aperture',
            },
            {
                text: [
                    "WE DO",
                    "WHAT WE",
                    "MUST, BECAUSE",
                    "WE CAN"
                ],
                delayForNextRow: 5272,
            },
            {
                text: [
                    "FOR THE",
                    "GOOD OF",
                    "ALL OF",
                    "US"
                ],
                delayForNextRow: 3240,
            },
            {
                text: [
                    "EXCEPT",
                    "THE ONES",
                    "WHO ARE",
                    "DEAD"
                ],
                delayForNextRow: 2236,
                ascii: 'nuclear',
            },
        ],
        [
            {
                text: [
                    "BUT",
                    "THERE'S",
                    "NO",
                    "SENSE"
                ],
                delayForNextRow: 1512,
                ascii: 'aperture',
            },
            {
                text: [
                    "CRYING",
                    "OVER",
                    "EVERY",
                    "MISTAKE"
                ],
                delayForNextRow: 2498,
            },
            {
                text: [
                    "YOU",
                    "JUST",
                    "KEEP",
                    "ON TRYING"
                ],
                delayForNextRow: 1994,
            },
            {
                text: [
                    "TILL YOU",
                    "RUN OUT",
                    "OF",
                    "CAKE"
                ],
                delayForNextRow: 2115,
            },
        ],
        [
            {
                text: [
                    "AND THE",
                    "SCIENCE",
                    "GETS",
                    "DONE"
                ],
                delayForNextRow: 1902,
                ascii: 'atom',
            },
            {
                text: [
                    "AND YOU",
                    "MAKE A",
                    "NEAT",
                    "GUN"
                ],
                delayForNextRow: 1986,
            },
            {
                text: [
                    "FOR THE",
                    "PEOPLE",
                    "WHO",
                    "ARE"
                ],
                delayForNextRow: 1854,
                ascii: 'aperture',
            },
            {
                text: [
                    "STI",
                    "LL",
                    "AL",
                    "IVE"
                ],
                delayForNextRow: 7393,
            },
        ],
        [
            {
                text: [
                    "I'M",
                    "NOT",
                    "EVEN",
                    "ANGRY"
                ],
                delayForNextRow: 4225,
                ascii: 'isc'
            },
            {
                text: [
                    "I'M",
                    "BEING",
                    "SO SINCERE",
                    "RIGHT NOW"
                ],
                delayForNextRow: 5022,
            },
            {
                text: [
                    "EVEN",
                    "THOUGH",
                    "YOU BROKE",
                    "MY HEART"
                ],
                delayForNextRow: 3505,
                ascii: 'heart',
            },
            {
                text: [
                    "AND",
                    "KIL",
                    "LED",
                    "ME"
                ],
                delayForNextRow: 3230,
            },
        ],
        [
            {
                text: [
                    "AND",
                    "TORE ME",
                    "TO",
                    "PIECES"
                ],
                delayForNextRow: 3993,
                ascii: 'explosion',
            },
            {
                text: [
                    "AND THREW",
                    "EVERY PIECE",
                    "INTO",
                    "A FIRE"
                ],
                delayForNextRow: 5278,
                ascii: 'fire',
            },
            {
                text: [
                    "AS THEY",
                    "BURNED",
                    "IT HURT",
                    "BECAUSE"
                ],
                delayForNextRow: 3240,
            },
            {
                text: [
                    "I WAS",
                    "SO",
                    "HAPPY",
                    "FOR YOU"
                ],
                delayForNextRow: 2245,
                ascii: 'ok',
            },
        ],
        [
            {
                text: [
                    "NOW THESE",
                    "POINTS",
                    "OF",
                    "DATA"
                ],
                delayForNextRow: 2004,
            },
            {
                text: [
                    "MAKE",
                    "A",
                    "BEAUTIFUL",
                    "LINE"
                ],
                delayForNextRow: 2018,
                ascii: 'pingui',
            },
            {
                text: [
                    "AND",
                    "WE'RE",
                    "OUT OF",
                    "BETA"
                ],
                delayForNextRow: 1984,
            },
            {
                text: [
                    "WE'RE",
                    "RELEASING",
                    "ON",
                    "TIME"
                ],
                delayForNextRow: 1994,
            },
        ],
        [
            {
                text: [
                    "SO I'M",
                    "GLAD",
                    "I GOT",
                    "BURNED"
                ],
                delayForNextRow: 2023,
                ascii: 'explosion',
            },
            {
                text: [
                    "THINK",
                    "OF ALL",
                    "THE THINGS",
                    "WE LEARNED"
                ],
                delayForNextRow: 1994,
                ascii: 'atom',
            },
            {
                text: [
                    "FOR THE",
                    "PEOPLE",
                    "WHO",
                    "ARE"
                ],
                delayForNextRow: 1757,
                ascii: 'aperture',
            },
            {
                text: [
                    "STI",
                    "LL",
                    "AL",
                    "IVE"
                ],
                delayForNextRow: 7726,
            },
        ],
        [
            {
                text: [
                    "GO",
                    "AHEAD",
                    "AND",
                    "LEAVE ME"
                ],
                delayForNextRow: 4012,
                ascii: 'noTasto',
            },
            {
                text: [
                    "I THINK",
                    "I PREFER",
                    "TO STAY",
                    "INSIDE"
                ],
                delayForNextRow: 4978,
            },
            {
                text: [
                    "MAYBE",
                    "YOU'LL",
                    "FIND",
                    "SOMEONE ELSE"
                ],
                delayForNextRow: 3534,
                ascii: 'isc',
            },
            {
                text: [
                    "TO",
                    "HE",
                    "LP",
                    "YOU?"
                ],
                delayForNextRow: 3476,
            },
        ],
        [
            {
                text: [
                    "MAYBE",
                    "BLACK",
                    "MESA",
                    "?"
                ],
                delayForNextRow: 3761,
                ascii: 'blackMesa',
            },
            {
                text: [
                    "THAT WAS",
                    "A JOKE",
                    "HAHA",
                    "FAT CHANCE"
                ],
                delayForNextRow: 5254,
            },
            {
                text: [
                    "ANYWAY",
                    "THIS",
                    "CAKE",
                    "IS GREAT"
                ],
                delayForNextRow: 3235,
                ascii: 'cake',
            },
            {
                text: [
                    "IT'S SO",
                    "DELICIOUS",
                    "AND",
                    "MOIST"
                ],
                delayForNextRow: 2255,
            },
        ],
        [
            {
                text: [
                    "LOOK AT",
                    "ME",
                    "STILL",
                    "TALKING"
                ],
                delayForNextRow: 2008,
                ascii: 'atom',
            },
            {
                text: [
                    "WHEN",
                    "THERE'S",
                    "SCIENCE",
                    "TO DO"
                ],
                delayForNextRow: 2245,
            },
            {
                text: [
                    "WHEN I",
                    "LOOK",
                    "OUT",
                    "THERE"
                ],
                delayForNextRow: 1733,
                ascii: 'aperture',
            },
            {
                text: [
                    "IT MAKES",
                    "ME GLAD",
                    "I'M NOT",
                    "YOU"
                ],
                delayForNextRow: 2042,
            },
        ],
        [
            {
                text: [
                    "I'VE",
                    "EXPERIMENTS",
                    "TO",
                    "RUN"
                ],
                delayForNextRow: 1960,
                'ascii': 'atom',
            },
            {
                text: [
                    "THERE IS",
                    "RESEARCH",
                    "TO",
                    "BE DONE"
                ],
                delayForNextRow: 2023,
                ascii: 'aperture',
            },
            {
                text: [
                    "ON THE",
                    "PEOPLE",
                    "WHO",
                    "ARE"
                ],
                delayForNextRow: 1743,
            },
            {
                text: [
                    "STI",
                    "LL",
                    "AL",
                    "IVE"
                ],
                delayForNextRow: 2260,
            },
        ],
        [
            {
                text: [
                    "PS: AND",
                    "BELIEVE",
                    "ME I",
                    "AM"
                ],
                delayForNextRow: 1868,
                ascii: 'isc',
            },
            {
                text: [
                    "STI",
                    "LL",
                    "AL",
                    "IVE"
                ],
                delayForNextRow: 1873,
            },
            {
                text: [
                    "PPS: I'M",
                    "DOING",
                    "SCIENCE",
                    "AND I'M"
                ],
                delayForNextRow: 1999,
            },
            {
                text: [
                    "STI",
                    "LL",
                    "AL",
                    "IVE"
                ],
                delayForNextRow: 2231,
            },
        ],
        [
            {
                text: [
                    "PPPS: I FEEL",
                    "FANTASTIC",
                    "AND",
                    "I'M"
                ],
                delayForNextRow: 1762,
                ascii: 'aperture',
            },
            {
                text: [
                    "STI",
                    "LL",
                    "AL",
                    "IVE"
                ],
                delayForNextRow: 2231,
            },
            {
                text: [
                    "WHILE",
                    "YOU'RE",
                    "DYING",
                    "I'LL BE"
                ],
                delayForNextRow: 1516,
                ascii: 'interloper1',
            },
            {
                text: [
                    "STI",
                    "LL",
                    "AL",
                    "IVE"
                ],
                delayForNextRow: 2221,
                ascii: 'aperture',
            },
        ],
        [
            {
                text: [
                    "AND WHEN",
                    "YOU'RE DEAD",
                    "I WILL",
                    "BE"
                ],
                delayForNextRow: 2008,
                ascii: 'interloper2',
            },
            {
                text: [
                    "ST",
                    "ILL",
                    "AL",
                    "IVE"
                ],
                delayForNextRow: 2023,
                ascii: 'cake',
            },
            {
                text: [
                    "GRAZIE",
                    "ISC",
                    "0xFREE",
                    "0xC0CC0"
                ],
                delayForNextRow: 2004,
            },
            {
                text: [
                    "SAVE",
                    "PIETRO",
                    "SAVE",
                    "THE WORLD"
                ],
                delayForNextRow: 4000,
                ascii: 'isc',
            },
        ]
    ],

    animateTotal: (donationsTotal) => {
        const el = document.getElementById('donation-amount-number');
        const elShadow = document.getElementById('donation-amount-number-shadow');
        const breakDonationAmount = document.querySelector('#break-donations .donation-total');
        anime({
            targets: oengusRenderer,
            totalDonationsCounter: donationsTotal,
            round: 100,
            easing: 'easeOutExpo',
            update: function () {
                let formattedNumber = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(oengusRenderer.totalDonationsCounter);
                el.innerHTML = formattedNumber;
                elShadow.innerHTML = formattedNumber;
                breakDonationAmount.innerHTML = formattedNumber;
            },
        });
    },

    updateDonations: () => {
        oengusRenderer.animateTotal(strapi.getTotalDonationsWithOffset());
    },

    updateDonationReason: () => {
        document.getElementById('donation-reason').innerHTML = strapi.getDonationReason();
        document.querySelector('#break-donations .donation-reason').innerHTML = strapi.getDonationReason();
    },

    updateDonatorsLeaderboard: () => {
        const donatorLeaderboard = strapi.getDonatorsLeaderboard();
        donatorLeaderboard.forEach((donation, index) => {
            setTimeout(function () {
                oengusRenderer.updateLeaderboard(index, donation);
            }, 1500 * index);
        })
        return;
    },

    updateLeaderboard: (index, donation) => {
        if (index === 0) oengusRenderer.updateLeaderboard1(donation);
        else if (index === 1) oengusRenderer.updateLeaderboard2(donation);
        else if (index === 2) oengusRenderer.updateLeaderboard3(donation);
    },

    updateLeaderboard1: (donation) => {
        const targetsToAnimate = [
            [
                document.querySelector('.leaderboard-1 .name'),
                document.querySelector('.leaderboard-1 .amount'),
            ],
            [
                document.querySelector('.leaderboard-2 .name'),
                document.querySelector('.leaderboard-2 .amount'),
            ],
            [
                document.querySelector('.leaderboard-3 .name'),
                document.querySelector('.leaderboard-3 .amount'),
            ]
        ];

        oengusRenderer.updateLeaderboard1And2(donation, targetsToAnimate);
    },

    updateLeaderboard2: (donation) => {
        const targetsToAnimate = [
            [
                document.querySelector('.leaderboard-2 .name'),
                document.querySelector('.leaderboard-2 .amount'),
            ],
            [
                document.querySelector('.leaderboard-3 .name'),
                document.querySelector('.leaderboard-3 .amount'),
            ]
        ];

        oengusRenderer.updateLeaderboard1And2(donation, targetsToAnimate);
    },

    setDonationToLeaderboardElement: (donation, target) => {
        target[0].innerHTML = donation.name;
        target[1].innerHTML = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(donation.total);
    },

    updateLeaderboard1And2: (donation, targets) => {
        if (!donation.updated) {
            oengusRenderer.setDonationToLeaderboardElement(donation, targets[0]);
            return;
        }

        const formattedAmount = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(donation.total);

        if (decodeEntities(targets[0][0].innerHTML) === donation.name && decodeEntities(targets[0][1].innerHTML) === formattedAmount) {
            // console.log("Skipping", donation);
            return;
        }

        let timeline = anime.timeline();
        // L'ultimo scompare
        timeline.add({
            targets: '.donator-leaderboard .last',
            opacity: 0,
            translateX: 20,
            duration: 200,
            easing: 'easeInOutQuad',
        });

        const targetsToAnimate = targets;

        timeline.add({
            targets: targetsToAnimate,
            translateX: 20,
            translateY: 31,
            duration: 200,
            easing: 'easeInOutQuad',
            complete: function () {
                let innerTimeline = anime.timeline();
                // partendo dall'ultimo, sostituisco il contenuto con il contenuto del precedente, fino all'indice corrente + 1
                targetsToAnimate.reverse();
                for (let x = 0; x < targetsToAnimate.length - 1; x++) {
                    targetsToAnimate[x][0].innerHTML = targetsToAnimate[x + 1][0].innerHTML;
                    targetsToAnimate[x][1].innerHTML = targetsToAnimate[x + 1][1].innerHTML;
                }
                targetsToAnimate.reverse();
                // riempio l'indice corrente con i nuovi dati
                targetsToAnimate[0][0].innerHTML = donation.name;
                targetsToAnimate[0][1].innerHTML = formattedAmount;
                // azzero le translation
                innerTimeline.add({
                    targets: targetsToAnimate,
                    translateX: 0,
                    translateY: 0,
                    easing: 'easeInOutQuad',
                    duration: 0,
                });
                // nascondo indice corrente e lo sposto di poco a sx
                innerTimeline.add({
                    targets: targetsToAnimate[0],
                    translateX: -20,
                    opacity: 0,
                    easing: 'easeInOutQuad',
                    duration: 0,
                });
                // mostro ultimo indice (se non è il corrente)
                if (targetsToAnimate.length > 1) {
                    innerTimeline.add({
                        targets: targetsToAnimate[targetsToAnimate.length - 1],
                        opacity: 1,
                        easing: 'easeInOutQuad',
                        duration: 0,
                    });
                }
                // faccio entrare con fade indice corrente
                innerTimeline.add({
                    targets: targetsToAnimate[0],
                    opacity: 1,
                    translateX: 0,
                    duration: 200,
                    easing: 'easeInOutQuad',
                });
            }
        });
    },

    updateLeaderboard3: (donation) => {
        const targetsToAnimate = [
            [
                document.querySelector('.leaderboard-3 .name'),
                document.querySelector('.leaderboard-3 .amount'),
            ]
        ];

        if (!donation.updated) {
            oengusRenderer.setDonationToLeaderboardElement(donation, targetsToAnimate[0]);
            return;
        }

        const formattedAmount = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(donation.total);

        if (decodeEntities(targetsToAnimate[0][0].innerHTML) === donation.name && decodeEntities(targetsToAnimate[0][1].innerHTML) === formattedAmount) {
            // console.log("Skipping", donation);
            return;
        }

        let timeline = anime.timeline();

        // L'ultimo scompare
        timeline.add({
            targets: targetsToAnimate[0],
            opacity: 0,
            translateX: 20,
            duration: 200,
            easing: 'easeInOutQuad',
            complete: function () {
                targetsToAnimate[0][0].innerHTML = donation.name;
                targetsToAnimate[0][1].innerHTML = formattedAmount;
            }
        }).add({
            targets: targetsToAnimate[0],
            translateX: -20,
            easing: 'easeInOutQuad',
            duration: 200,
        }).add({
            targets: targetsToAnimate[0],
            opacity: 1,
            translateX: 0,
            duration: 200,
            easing: 'easeInOutQuad',
        });
    },

    updateRunnerName: () => {
        document.querySelectorAll('.current-runner-1 .runner-name').forEach(el => {
            el.innerHTML = strapi.getRunnerName(1);
        })
        document.querySelectorAll('.current-runner-2 .runner-name').forEach(el => {
            el.innerHTML = strapi.getRunnerName(2);
        })
    },

    fadeInOutRunnerName: (ord) => {
        const runnerName = document.querySelectorAll('.current-runner-' + ord + ' .runner-name');
        if (strapi.getRunnerSocialsData(ord).length === 0) {
            runnerName.forEach(function (e) { e.style.opacity = 1; });
            setTimeout(function () { oengusRenderer.fadeInOutRunnerName(ord) }, oengusRenderer.runnerNameShowDelay);
        } else {
            runnerName.forEach(function (e) { e.style.opacity = 0; });
            anime({
                targets: runnerName,
                opacity: 1,
                duration: oengusRenderer.runnerNameShowDuration,
                endDelay: oengusRenderer.runnerNameShowDelay / 4,
                easing: 'easeInOutExpo',
                direction: 'alternate',
                complete: function () {
                    oengusRenderer.fadeInOutRunnerSocials(ord);
                },
            });
        }
    },

    fadeInOutRunnerSocials: (ord) => {
        const socialHandle = document.querySelectorAll('.current-runner-' + ord + ' .social .handle');
        const socialIcon = document.querySelectorAll('.current-runner-' + ord + ' .social .icon');
        let socials = strapi.getRunnerSocialsData(ord);
        let handleTimeline = anime.timeline();
        let iconTimeline = anime.timeline();
        socials.forEach(function callback(socialData, i) {
            handleTimeline.add({
                targets: socialHandle,
                opacity: 1,
                duration: oengusRenderer.runnerNameShowDuration,
                endDelay: oengusRenderer.runnerSocialShowDelay,
                easing: 'easeInOutExpo',
                begin: function () {
                    socialHandle.forEach(function (el) { el.innerHTML = socialData.handle; });
                },
            }).add({
                targets: socialHandle,
                opacity: 0,
                duration: oengusRenderer.runnerNameShowDuration,
                easing: 'easeInOutExpo',
                complete: function () {
                    if (i === socials.length - 1) {
                        oengusRenderer.fadeInOutRunnerName(ord);
                    }
                }
            });
            iconTimeline.add({
                targets: socialIcon,
                translateX: 43,
                duration: 2000,
                endDelay: oengusRenderer.runnerSocialShowDelay,
                easing: 'easeInOutExpo',
                begin: function () {
                    socialIcon.forEach(function (el) { el.src = socialData.icon; });
                },
            }).add({
                targets: socialIcon,
                translateX: 0,
                duration: 2000,
                easing: 'easeInOutExpo',
            });
        });
    },

    updateCurrentRunInfo: () => {
        if (oengusRenderer.gameTabFilled) return;

        const titleElement = document.querySelector('#current-run-game-tab .title');
        const categoryElement = document.querySelector('#current-run-game-tab .category');
        const elapsedElement = document.querySelector('#current-run-game-tab .elapsed');
        const platformElement = document.querySelector('#current-run-game-tab .platform');
        const yearElement = document.querySelector('#current-run-game-tab .year');

        let playingNow = oengusRenderer.getCurrentRun();

        const gameName = playingNow?.name ?? '';
        const categoryName = playingNow?.submission?.category ?? '';
        const platform = playingNow?.submission?.platform?.name ?? '';
        titleElement.innerHTML = gameName;
        categoryElement.innerHTML = categoryName;
        platformElement.innerHTML = platform;

        if (!!gameName) titleElement.classList.add('show');
        if (!!categoryName) categoryElement.classList.add('show');
        if (!!platform) platformElement.classList.add('show');

        let estimateDuration = playingNow?.duration ?? 0;
        let estimate = oengus.getDurationFromSeconds(estimateDuration);

        elapsedElement.innerHTML = estimate.format('HH:mm:ss');
        if (estimateDuration !== 0)
            elapsedElement.classList.add('show');

        const year = playingNow?.submission?.year;
        yearElement.innerHTML = year;
        if (!!year)
            yearElement.classList.add('show');

        if (!!gameName)
            oengusRenderer.gameTabFilled = true;

        oengusRenderer.decreaseFontSizeUntilItFits(titleElement);
    },

    decreaseFontSizeUntilItFits(element, extraFontDecrease = 0) {
        while (oengusRenderer.isElementOverflowing(element)) {
            console.log("Overflowing title: decreasing font size");
            let fontSize = parseInt(window.getComputedStyle(element).fontSize);
            console.log("Current font size:", fontSize);
            element.style.fontSize = (fontSize - 1) + 'px';
            if (fontSize <= 10)
                break;
        }
        if (extraFontDecrease > 0) {
            let fontSize = parseInt(window.getComputedStyle(element).fontSize);
            element.style.fontSize = (fontSize - extraFontDecrease) + 'px';
        }
    },

    isElementOverflowing: (element) => {
        return element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
    },

    updateHostName: () => {
        const hostNameElement = document.querySelector('#host .host-name');
        hostNameElement.innerHTML = strapi.getHostName();
    },

    updateCouch: () => {
        const couchElement = document.querySelector('#couch');
        const couchNamesElement = couchElement.querySelector('.couch-names');

        const couchNames = strapi.getCouch();
        couchNamesElement.innerHTML = couchNames;

        if (!couchNames)
            couchElement.style.opacity = 0;
        else
            couchElement.style.opacity = 1;
    },

    updateBreakScreenHost: () => {
        const breakHostElement = document.querySelector('#break-host');
        const breakHostNameElement = breakHostElement.querySelector('.break-host-name');

        const breakHostName = strapi.getBreakScreenHost();
        breakHostNameElement.innerHTML = breakHostName;

        if (!breakHostName)
            breakHostElement.style.opacity = 0;
        else
            breakHostElement.style.opacity = 1;
    },

    getCurrentRun: () => {
        return oengus.getPlayingNow();
    },

    getNextRun: (index) => {
        return oengus.getNextRun(index);
    },

    getRunnerFormattedString: (run) => {
        const runners = run?.submission?.runners?.map(r => r.user?.name) ?? [];
        return runners.join(' <span class="no-hl">e</span> ');
    },

    getRunElapsedFormattedString: (run) => {
        let estimateDuration = run?.duration ?? 0;
        let estimate = oengus.getDurationFromSeconds(estimateDuration);
        return estimate.format('HH:mm:ss');
    },

    updateAndShowAllBreakRuns: () => {
        if (!oengusRenderer.breakFirstShow) return;

        let timeline = anime.timeline();
        oengusRenderer.updateBreakNextRun(timeline);
        for (let x = 1; x <= 5; x++) {
            oengusRenderer.updateBreakNextNthRun(x, timeline);
        }

        oengusRenderer.showAdBreak(oengusRenderer.currentAdBreakIndex);

        oengusRenderer.breakFirstShow = true;
    },

    updateBreakRunCommonData: (rootElement, data, index) => {
        const nameElement = document.querySelector(rootElement + ' .what .name');
        const categoryElement = document.querySelector(rootElement + ' .what .category');
        const runnerElement = document.querySelector(rootElement + ' .who .name');
        const elapsedElement = document.querySelector(rootElement + ' .who .elapsed');

        let changed = false;
        changed = nameElement.innerHTML === data?.name ?? '';
        changed = categoryElement.innerHTML === data?.submission?.category ?? '';

        const runnerFormattedString = oengusRenderer.getRunnerFormattedString(data);

        const gameName = data?.name ?? '';
        const categoryName = data?.submission?.category ?? '';
        nameElement.innerHTML = gameName;
        categoryElement.innerHTML = categoryName;
        runnerElement.innerHTML = runnerFormattedString;
        elapsedElement.innerHTML = oengusRenderer.getRunElapsedFormattedString(data);

        const whoElement = document.querySelector(rootElement + ' .who');
        if (runnerFormattedString === '') {
            whoElement.classList.add('hide');
        } else {
            whoElement.classList.remove('hide');
        }

        if (gameName === '' && categoryName === '') {
            const chevronElement = document.querySelector(rootElement + ' .chevron');
            chevronElement.classList.add('hide');
            const endElement = document.querySelector(rootElement + ' .end');
            endElement.classList.remove('hide');
            const endElementSpan = document.querySelector(rootElement + ' .end span');
            endElementSpan.innerHTML = "> <span class='fizzle fizzle-1 row-" + oengusRenderer.endCounter + "'>GIOCATORE</span> <span class='fizzle fizzle-2 row-" + oengusRenderer.endCounter + "'>" + oengusRenderer.endCounter + "</span> <span class='fizzle fizzle-3 row-" + oengusRenderer.endCounter + "'>PREMI</span> <span class='fizzle fizzle-4 row-" + oengusRenderer.endCounter + "'>START</span> <";
            oengusRenderer.endCounter++;
        }

        if (index === 0) {
            nameElement.classList.remove('alternate');
            categoryElement.classList.remove('alternate');

            if (nameElement.getBoundingClientRect().top !== categoryElement.getBoundingClientRect().top) {
                nameElement.classList.add('alternate');
                categoryElement.classList.add('alternate');
            }
        }

        oengusRenderer.decreaseFontSizeUntilItFits(nameElement, 1);

        return changed;
    },

    singLevel(level) {
        for (let currentRow = 0; currentRow < 4; currentRow++) {
            oengusRenderer.singRow(level, currentRow, false, true);
        }
    },

    singRow(level, row, autoAdvance = true, useTimer = false) {
        const currentRow = oengusRenderer.lyrics[level][row];
        console.log("song currentRow", currentRow);
        if (currentRow.ascii)
            oengusRenderer.showSongAscii(currentRow.ascii);

        if (currentRow.function)
            window['oengusRenderer'][currentRow.function]();

        for (let currentWord = 0; currentWord < 4; currentWord++) {
            let toFizzle = oengusRenderer.getFizzle(row + 1, currentWord + 1)
            setTimeout(function () {
                oengusRenderer.fizzleOneWithWord(toFizzle, currentRow.text[currentWord], 7)
            }, (140 * currentWord) + (row * 2400 * (useTimer ? 1 : 0)));
        }

        if (!autoAdvance)
            return;

        row++;
        if (row >= 4) {
            row = 0;
            level++;
        }

        if (level >= oengusRenderer.lyrics.length) {
            console.log("song ended");

            setTimeout(function () {
                anime({
                    targets: '.break-opacity',
                    duration: 3000,
                    easing: 'linear',
                    opacity: 0,
                    complete: function () {
                        document.querySelector(".ad-container").style.display = 'block';
                        document.querySelector(".incentive-container").style.display = 'flex'
                        document.querySelector(".song-container").style.display = 'none';

                        anime({
                            targets: '.break-opacity',
                            duration: 3000,
                            easing: 'linear',
                            opacity: 1,
                        });
                    }
                });
            }, 8000);

            return;
        }

        console.log("song level, row", level, row);

        setTimeout(function () {
            oengusRenderer.singRow(level, row);
        }, currentRow.delayForNextRow);
    },

    showSongAscii: (asciiName) => {
        const heart = `                          .,---.
                        ,/XM#MMMX;,
                      -%##########M%,
                     -@######%  $###@=
      .,--,         -H#######$   $###M:
   ,;$M###MMX;     .;##########$;HM###X=
,/@###########H=      ;################+
-+#############M/,      %##############+
%M###############=      /##############:
H################      .M#############;.
@###############M      ,@###########M:.
X################,      -$=X#######@:
/@##################%-     +######$-
.;##################X     .X#####+,
 .;H################/     -X####+.
   ,;X##############,       .MM/
      ,:+$H@M#######M#$-    .$$=
           .,-=;+$@###X:    ;/=.
                  .,/X$;   .::,
                      .,    ..`;

        const aperture = `             .,-:;//;:=,
         . :H@@@MM@M#H/.,+%;,
      ,/X+ +M@@M@MM%=,-%HMMM@X/,
     -+@MM; $M@@MH+-,;XMMMM@MMMM@+-
    ;@M@@M- XM@X;. -+XXXXXHHH@M@M#@/.
  ,%MM@@MH ,@%=            .---=-=:=,.
  -@#@@@MX .,              -%HX$$%%%+;
 =-./@M@M$                  .;@MMMM@MM:
 X@/ -$MM/                    .+MM@@@M$
,@M@H: :@:                    . -X#@@@@-
,@@@MMX, .                    /H- ;@M@M=
.H@@@@M@+,                    %MM+..%#$.
 /MMMM@MMH/.                  XM@MH; -;
  /%+%$XHH@$=              , .H@@@@MX,
   .=--------.           -%H.,@@@@@MX,
   .%MM@@@HHHXX$$$%+- .:$MMX -M@@MM%.
     =XMMM@MM@MM#H;,-+HMM@M+ /MMMX=
       =%@M@M#@$-.=$@MM@@@M; %M%=
         ,:+$+-,/H#MMMMMMM@- -,
               =++%%%%+/:-.`;

        const nuclear = `             =+$HM####@H%;,
          /H###############M$,
          ,@################+
           .H##############+
             X############/
              $##########/
               %########/
                /X/;;+X/

                 -XHHX-
                ,######,
#############X  .M####M.  X#############
##############-   -//-   -##############
X##############%,      ,+##############X
-##############X        X##############-
 %############%          %############%
  %##########;            ;##########%
   ;#######M=              =M#######;
    .+M###@,                ,@###M+.
       :XH.                  .HX:`;

        const atom = `                 =/;;/-
                +:    //
               /;      /;
              -X        H.
.//;;;:;;-,   X=        :+   .-;:=;:;%;.
M-       ,=;;;#:,      ,:#;;:=,       ,@
:%           :%.=/++++/=.$=           %=
 ,%;         %/:+/;,,/++:+/         ;+.
   ,+/.    ,;@+,        ,%H;,    ,/+,
      ;+;;/= @.  .H##X   -X :///+;
      ;+=;;;.@,  .XM@$.  =X.//;=%/.
   ,;:      :@%=        =$H:     .+%-
 ,%=         %;-///==///-//         =%,
;+           :%-;;;;;;;;-X-           +:
@-      .-;;;;M-        =M/;;;-.      -X
 :;;::;;-.    %-        :+    ,-;;-;:==
              ,X        H.
               ;/      %=
                //    +;
                 ,////,`;

        const fire = `                     -$-
                    .H##H,
                   +######+
                .+#########H.
              -$############@.
            =H###############@  -X:
          .$##################:  @#@-
     ,;  .M###################;  H###;
   ;@#:  @###################@  ,#####:
 -M###.  M#################@.  ;######H
 M####-  +###############$   =@#######X
 H####$   -M###########+   :#########M,
  /####X-   =########%   :M########@/.
    ,;%H@X;   .$###X   :##MM@%+;:-
                 ..
  -/;:-,.              ,,-==+M########H
 -##################@HX%%+%%$%%%+:,,
    .-/H%%%+%%$H@###############M@+=:/+:
/XHX%:#####MH%=    ,---:;;;;/&&XHM,:###$
$@#MX %+;-                           .`;

        const ok = `                                     :X-
                                  :X###
                                ;@####@
                              ;M######X
                            -@########$
                          .$##########@
                         =M############-
                        +##############$
                      .H############$=.
         ,/:         ,M##########M;.
      -+@###;       =##########M;
   =%M#######;     :#########M/
-$M###########;   :########/
 ,;X###########; =#######$.
     ;H#########+######M=
       ,+#############+
          /M########@-
            ;M#####%
              +####:
               ,$M-`;

        const explosion = `            .+
             /M;
              H#@:              ;,
              -###H-          -@/
               %####$.  -;  .%#X
                M#####+;#H :M#M.
..          .+/;%#############-
 -/%H%+;-,    +##############/
    .:$M###MH$%+############X  ,--=;-
        -/H#####################H+=.
           .+#################X.
         =%M####################H;.
            /@###############+;;/%%;,
         -%###################$
       ;H######################M=
    ,%#####MH$%;+#####M###-/@####%
  :$H%+;=-      -####X.,H#   -+M##@-
 .              ,###;    ;      =$##+
                .#H,               :XH,
                 +                   .;-`;

        const blackMesa = `           .-;+$XHHHHHHX$+;-.
        ,;X@@X%/;=----=:/%X@@X/,
      =$@@%=.              .=+H@X:
    -XMX:                      =XMX=
   /@@:                          =H@+
  %@X,                            .$@$
 +@X.                               $@%
-@@,                                .@@=
%@%                                  +@$
H@:                                  :@H
H@:         :HHHHHHHHHHHHHHHHHHX,    =@H
%@%         ;@M@@@@@@@@@@@@@@@@@H-   +@$
=@@,        :@@@@@@@@@@@@@@@@@@@@@= .@@:
 +@X        :@@@@@@@@@@@@@@@M@@@@@@:%@%
  $@$,      ;@@@@@@@@@@@@@@@@@M@@@@@@$.
   +@@HHHHHHH@@@@@@@@@@@@@@@@@@@@@@@+
    =X@@@@@@@@@@@@@@@@@@@@@@@@@@@@X=
      :$@@@@@@@@@@@@@@@@@@@M@@@@$:
        ,;$@@@@@@@@@@@@@@@@@@X/-
           .-;+$XXHHHHHX$+;-.`;

        const cake = `            ,:/+/-
            /M/              .,-=;//;-
       .:/= ;MH/,    ,=/+%$XH@MM#@:
      -$##@+$###@H@MMM#######H:.    -/H#
 .,H@H@ X######@ -H#####@+-     -+H###@X
  .,@##H;      +XM##M/,     =%@###@X;-
X%-  :M##########$.    .:%M###@%:
M##H,   +H@@@$/-.  ,;$M###@%,          -
M####M=,,---,.-%%H####M$:          ,+@##
@##################@/.         :%H##@$-
M###############H,         ;HM##M$=
#################.    .=$M##M$=
################H..;XM##M$=          .:+
M###################@%=           =+@MH%
@#################M/.         =+H#X%=
=+M###############M,      ,/X#H+:,
  .;XM###########H=   ,/X#H+:;
     .=+HM#######M+/+HM@+=.
         ,:/%XM####H/.
              ,.:=-.`;

        const glados = `       #+ @      # #              M#@
 .    .X  X.%##@;# #   +@#######X. @H%
   ,==.   ,######M+  -#####%M####M-    #
  :H##M%:=##+ .M##M,;#####/+#######% ,M#
 .M########=  =@#@.=#####M=M#######=  X#
 :@@MMM##M.  -##M.,#######M#######. =  M
             @##..###:.    .H####. @@ X,
   ############: ###,/####;  /##= @#. M
           ,M## ;##,@#M;/M#M  @# X#% X#
.%=   ######M## ##.M#:   ./#M ,M #M ,#$
##/         $## #+;#: #### ;#/ M M- @# :
#+ #M@MM###M-;M #:$#-##$H# .#X @ + $#. #
      ######/.: #%=# M#:MM./#.-#  @#: H#
+,.=   @###: /@ %#,@  ##@X #,-#@.##% .@#
#####+;/##/ @##  @#,+       /#M    . X,
   ;###M#@ M###H .#M-     ,##M  ;@@; ###
   .M#M##H ;####X ,@#######M/ -M###$  -H
    .M###%  X####H  .@@MM@;  ;@#M@
      H#M    /@####/      ,++.  / ==-,
               ,=/:, .+X@MMH@#H  #####$=`;

        const isc = `
        
        
        
        
  .@@@@    -+#@@@@%#=.      .=*%@@@@%#= 
  =@@@#  =@@@@@@@@@@@@=   =%@@@@@@@@@=-:
  #@@@+ =@@@%:   .+@@#- =@@@@%=:...:-*: 
  @@@@: #@@@*      :.  *@@@%:     =%*   
 :@@@@  =@@@@@%#*=:   =@@@%     =@@-    
 +@@@*   :*@@@@@@@@@+ %@@@-    =@#.     
 %@@@-      .:-+#@@@@+%@@@+             
.@@@@. +@#.      #@@@+-@@@@+            
=@@@% @@@@@*=--+#@@@#  =@@@@@#+++*#*    
#@@@+ .+@@@@@@@@@@%-    .+@@@@@@@@@@*   `;

        const lfr = `              **.                       
             +#+                        
           -*#=   .       :             
        .-=-+#*:=#+:.   .+:             
           -+**=-.     =*-              
        .=**=.       -++=   .:          
       :**-        :++++      --        
      :*+.       .=++++.       :=       
      ++        -+++++:         -=      
     :+:      :======-          .*.     
     :+     :==========-:.       +:     
     .+      .::-==========-:.  .+:     
      =:          .:-======-:   =+      
       =.          .-====-.    -+:      
        -.        :----:     .=+-       
         .:      ----.     .-==.        
            .  .--:     .-==-.          
              :-.   ..:::..             
            .:.`;

        const mdm = `            .:--==+++++==-:.            
         :=++++---=+:=+--=+++=:         
      .=++=-:++ =- + =.-==-:++++=.      
    .=++=+-.:-=:.::+.+.:=-.=++++++=.    
   -+++= .:=.:-++++++++++-=+++++++++-   
  =++- :: --+++----------=+++++++++++=  
 -+++++-:=++=--++---:-+++=--+++-=-.+++- 
.++++++++++--++++.    .++++==++ -:-:.-+.
=+++++++++--+++++:     =:::-+++--=-:=++=
++++++++++.++             . :+-=-.:::=++
+++--::=++.++              .::-++=-+++++
=++ ==::++:=+=:...     ::-=++:+++++++++=
.++=---:-++:=++++=     -++++:=+=-=+++++:
 -++:..::=++--+++=    :+++--++-.-:.=++= 
  =++.==-.=+++----====----=+=--:::.=+=  
   -+++.:++++==++++===+++==:.=:.++++-   
    .=++:++++. +=.=:::=:.-.=-.::++=.    
      .=++++-:....-.+-.=.-:.++++=:      
        .:=+++=+-=+:::-+==+++=:.        
            .:-===+++++==-:.`;

        const bolognaNerd = `            :-=+*##%%##**=-:            
        .-*%%%%%%%%%%%%%%%%%%*=.        
      :*%%%%%%%%%%%%#*#**#*%%%%%*:      
    :*%%%%%%%%%%%%%%++++++=%%%%%%%#:    
   =%%%%%%%%%%%%%%%%%-+**=+%%%%%%%%%+   
  *%%%%%%%%%%%%%%%%#=======*%%%%%%%%%*  
 +%%%%%%%%%%%%%%%%%%+***+*+#%%%%%%%%%%* 
:%%%%%%%%%%%%%%*#%%%%=#%%+#%%%%%%%%%%%%-
*%%%%%%%%%%%%%*+***=#=#%%+#%%%%%%%%%%%%*
#%%%%%%%%%%%%%=%%+*=#-+++==%%%%%%%%%%%%%
#%%%%%%%%%%%%**%*++#++****=+%%%%%%%%%%%%
*%%%%%%%%%%%%-%%+*=%-######=%%%%%%%%%%%*
:%%%%%%%%%%#+*#:**+*=%%%%%%-#%%%%%%%%%%-
 +%%%%%%%%%=#+*#***#***+*#+%=%%%%%%%%%* 
  *%%%%%%%%=#+#+*++**+*#++*#=%%%%%%%%*  
   +%%%%%%%=* : + :+.: = + #=%%%%%%%+   
    :#%%%%%=#-#-*-=+-+-*-==%=%%%%%#-    
      -*%%%#****************#%%%#-      
        .=*%%%%%%%%%%%%%%%%%%#=.        
            :-+*##%%%%##*+=:`;

        const pingui = `                                        
         :-.                            
       .*++++--:                        
      .****+=-:.                        
  +==+***+*+:                           
 .=*++=.:=+=:-                          
   *#=-+::+++=====:                     
   =#*-.  *++*-                         
    =#+  .-=++=.                        
     .++.     .                         
       :*=.     .               =-:-==+-
        =#*-::   .-          :=++++++:  
       :=**--==.  -==-:::--==++++*+:    
    :=+===++--==-:-..=#*++++++**+:      
  .+++++++***+++-:--=.-**#**#+-         
 =*+++++*##**+++-::--..-**=:            
-#*++=++#%##*+++-::.-=::*:              
%#*+++====+++++=-:::=-::*.              
====------::::-:....::..-`;

        const noTasto = `
                          =***=:.       
                         *%#*#%%%%+     
                       .:*%%%%@@@@@%    
                       -+*#@@@@@@@@@:   
     ==:                *%@%%%@@@@@*    
 :=+==++=:              #%%@@%%@%%+     
  .++===++*++++-:::::-=+*%@%##%%%%*-.   
    .--:::+****+======++==#*+====+=---. 
             ::::-------=+*++=--::---=- 
                        -+*===+--::::-: 
                       :-**=**=::::::-: 
                       -=*##==--:::::-- 
                       =+%#+--=---:::--.
                      -=*%*=====------- 
                     .++*#+*++*==------ 
                   -*%%+***++**====-==: 
               ---=#@@%***#+***+======  
             -+**++=+*%%%#*+#*+=+====-  `;

        const interloper1 = `
                 ::-=-.                 
             .+%@@@#@@@@%*:             
           :#@@@@@@=@@@@@@@%=           
          =@@@#%@@@%=@@@@@@@@=          
          @@@@@#***#-%%#****%@          
          -@@@@@@@@@+-#%@@@@@=          
           -%@@%%%@@#+@@@@@%=           
             ++@@@+%#+@@@#=             
           .*@:+@@ %#+%-                
          .@@@%...+@-.-@:               
          .@@@@@*%@@@-:@-               
          :@@@@@@@@@@@@@=               
          :@@@@@@@@@@@%=.               
          :@@@@@@@@@@@+                 
           .+@@@@@@@@@=                 
            .@@@@@@@@@=                 
            .@@@@@@@@@-                 
            .@@@@@@@@@-                 
            .@@@@@@@@@:                 
             +++++++++.                 `;


        const interloper2 = `
░░░░░░░░░░░░░░░░░░▒▒▒▒░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░▓████▓█████▓▒░░░░░░░░░░░░░
░░░░░░░░░░░▒███████▒████████▒░░░░░░░░░░░
░░░░░░░░░░▒███▓█████▒████████▒░░░░░░░░░░
░░░░░░░░░░██████▓▓▓▓▒███▓▓▓▓██░░░░░░░░░░
░░░░░░░░░░▒█████████▓▒███████▒░░░░░░░░░░
░░░░░░░░░░░▒████████▓▓██████▒░░░░░░░░░░░
░░░░░░░░░░░░░▓▓███▓█▓▓████▒░░░░░░░░░░░░░
░░░░░░░░░░░░▓█▒▓██░█▓▓█▒░░░░░░░░░░░░░░░░
░░░░░░░░░░░████░░░▓█▒░▒█▒░░░░░░░░░░░░░░░
░░░░░░░░░░░█████▓████▒░█▒░░░░░░░░░░░░░░░
░░░░░░░░░░░█████████████▒░░░░░░░░░░░░░░░
░░░░░░░░░░░████████████▒░░░░░░░░░░░░░░░░
░░░░░░░░░░▒███████████▓░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░▓█████████▒░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░█████████▒░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░█████████▒░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░█████████▒░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░█████████░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░▓▓▓▓▓▓▓▓▓░░░░░░░░░░░░░░░░░░`;

        const ascii = {
            heart: heart,
            aperture: aperture,
            nuclear: nuclear,
            atom: atom,
            fire: fire,
            ok: ok,
            explosion: explosion,
            blackMesa: blackMesa,
            cake: cake,
            glados: glados,
            isc: isc,
            lfr: lfr,
            mdm: mdm,
            bolognaNerd: bolognaNerd,
            pingui: pingui,
            noTasto: noTasto,
            interloper1: interloper1,
            interloper2: interloper2,
        };

        const asciiElement = document.querySelector('#song-ascii');
        asciiElement.innerHTML = '';
        oengusRenderer.showNextChar(asciiElement, ascii[asciiName]);
    },

    asciiTimer: undefined,
    showNextChar: (element, text, index = 0) => {
        if (oengusRenderer.asciiTimer)
            clearTimeout(oengusRenderer.asciiTimer);

        oengusRenderer.asciiTimer = setTimeout(function () {
            oengusRenderer.appendAscii(element, text.charAt(index));
            index++;
            if (index >= text.length)
                return;

            oengusRenderer.appendAscii(element, text.charAt(index));
            index++;
            if (index >= text.length)
                return;

            oengusRenderer.showNextChar(element, text, index);
        }, 1);
    },

    appendAscii: (element, char) => {
        element.innerHTML += char;
    },

    getFizzle(row, index) {
        return document.querySelector('#break-next .fizzle-' + index + '.row-' + row);
    },

    startFizzleWatcher: () => {
        const fizzleableLength = oengusRenderer.getFizzleable().length / 4;
        console.log("Started fizzle watcher: length", fizzleableLength);
        if (fizzleableLength <= 0) return;

        if (fizzleableLength != 4) {
            const fizzleInterval = 720000 / (fizzleableLength + 1) / 2;
            console.log("Fizzling every", fizzleInterval);
            oengusRenderer.fizzleWatcherInterval = setInterval(function () {
                oengusRenderer.fizzleTimer(4, 25);
                console.log("Fizzled");
            }, fizzleInterval);
        } else {
            if (!window.playBgm) {
                console.log("Requested song, but no playBgm function.");
            } else {
                console.log("Requested song.");
                console.log("Song available from", process.env.SONG_DATE_AVAILABLE, dayjs().format());
                if (!cake && !dayjs().isAfter(process.env.SONG_DATE_AVAILABLE)) {
                    console.log("Song is not yet available.");
                    return;
                }

                if (strapi.shouldSendAds) {
                    clearTimeout(strapi.adSendingTimeout);
                    console.log("Clearing ad timeout in favor of song.");
                    strapi.adSendingTimeout = setTimeout(strapi.sendAds, 240000);
                    console.log("Set ad timeout for 240s.");
                }

                setTimeout(function () {
                    anime({
                        targets: '#media-player audio',
                        duration: 3000,
                        easing: 'linear',
                        volume: 0,
                        complete: function () {
                            strapi.playBgm({
                                caption: "Mike Pouch",
                                alternativeText: "RECEIVING EXTERNAL TRANSMISSION",
                                mime: "audio/mpeg",
                                url: "/uploads/Portal_Still_Alive_6cf53d1097.mp3"
                            });
                            document.querySelector("#media-player audio").volume = 0.2;

                            oengusRenderer.singRow(0, 0);
                        }
                    });

                }, 25000);
            }
        }
    },

    fadeOutAdBreak: () => {
        anime({
            targets: '.break-opacity',
            duration: 3000,
            easing: 'linear',
            opacity: 0,
            complete: function () {
                document.querySelector(".ad-container").style.display = 'none';
                document.querySelector(".incentive-container").style.display = 'none'
                document.querySelector(".break-opacity").style.opacity = 1;
            }
        });
    },

    fizzleTimer: (level, interval) => {
        const fizzleIntervalN = setInterval(function () { oengusRenderer.fizzleOneRandom(level); }, interval);
        setTimeout(function () {
            clearInterval(fizzleIntervalN);
        }, oengusRenderer.getRandomNumber(100 * level, 400 * level));
    },

    getFizzleable() {
        return document.querySelectorAll('#break-next .fizzle');
    },

    fizzleOneRandom: (level) => {
        let words = ["0xC0", "0xCC", "0x00", "0xG4", "0xM3"];

        const fizzleable = oengusRenderer.getFizzleable();
        const toFizzle = fizzleable[oengusRenderer.getRandomNumber(0, fizzleable.length)];
        const word = words[oengusRenderer.getRandomNumber(0, words.length)];
        oengusRenderer.fizzleOneWithWord(toFizzle, word, level);

    },

    fizzleOneWithWord(toFizzle, word, level) {
        if (oengusRenderer.fizzling.includes(toFizzle)) return;

        oengusRenderer.fizzling.push(toFizzle);
        const oldContents = toFizzle.innerHTML;
        toFizzle.innerHTML = word;
        const timeoutDuration = oengusRenderer.getRandomNumber(600 * level, 900 * level);
        setTimeout(function () {
            toFizzle.innerHTML = oldContents;
            var index = oengusRenderer.fizzling.indexOf(toFizzle);
            if (index !== -1) {
                oengusRenderer.fizzling.splice(index, 1);
            }
        }, timeoutDuration);
    },

    getRandomNumber(min, max) {
        return parseInt(Math.random() * (max - min) + min);
    },

    updateBreakNextRun: (timeline) => {
        const nextRun = oengusRenderer.getNextRun(0);
        const rootElement = '#break-next .next-run';

        if (nextRun.length === 0)
            return;

        oengusRenderer.updateBreakRunCommonData(rootElement, nextRun, 0);
        oengusRenderer.showBreakRun(rootElement, timeline, 0);

        console.log("NEXT RUN:", nextRun);
    },

    updateBreakNextNthRun: (index, timeline) => {
        const nextRun = oengusRenderer.getNextRun(index);
        const rootElement = '#break-next .future-runs .game:nth-child(' + index + ')';

        oengusRenderer.updateBreakRunCommonData(rootElement, nextRun, index);

        const timeInElement = document.querySelector(rootElement + ' .when .in');
        const timeEstimateElement = document.querySelector(rootElement + ' .when .estimate');

        // .when .in/.estimate
        const waitTime = flagRenderer.getWaitTime(nextRun.start_date);
        timeInElement.innerHTML = waitTime.label;
        timeEstimateElement.innerHTML = waitTime.duration;

        oengusRenderer.showBreakRun(rootElement, timeline, index);
    },

    showBreakRun: (rootElement, timeline, index) => {
        const animeData = {
            targets: rootElement,
            opacity: 1,
            easing: 'easeInOutQuad',
            duration: 2000,
            delay: index === 0 ? 1500 : 0,
        };
        if (!!timeline) {
            timeline.add(animeData, '-=1000');
        } else {
            anime(animeData);
        }

        if (index === 0) {
            oengusRenderer.breakNextGameNameAlternateAnimation = anime({
                // hides name if alternate mode is on
                targets: document.querySelector(rootElement + ' .name.alternate'),
                opacity: 0,
                easing: 'linear',
                duration: 250,
                direction: 'alternate',
                loop: true,
                delay: 4000,
                endDelay: 3000,
            });
            oengusRenderer.breakNextGameCategoryAlternateAnimation = anime({
                // shows category if alternate mode is on
                targets: document.querySelector(rootElement + ' .category.alternate'),
                opacity: 1,
                easing: 'linear',
                duration: 250,
                direction: 'alternate',
                loop: true,
                delay: 4000,
                endDelay: 3000,
            });
        }
    },

    maybeUpdateDonationGoal: () => {
        if (!oengusRenderer.shouldUpdateDonationGoal) return;

        oengusRenderer.updateDonationGoal();
    },

    updateDonationGoal: () => {
        oengusRenderer.maybeResetDonationGoalPosition();
        const flairObjectiveEl = document.querySelector('#donation-progress .flair-objective');
        flairObjectiveEl.innerHTML = strapi.getDonationGoalFlair();

        const flairObjectiveAmountEl = document.querySelector('#donation-progress .donation-totals');
        const donationGoalAmount = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(strapi.getDonationGoalAmount());
        const totalDonations = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(strapi.getTotalDonationsWithOffset());
        flairObjectiveAmountEl.innerHTML = totalDonations + ' / ' + donationGoalAmount;

        oengusRenderer.goalDonationPercent = strapi.getTotalDonationsWithOffset() * 100 / strapi.getDonationGoalAmount();
        if (oengusRenderer.goalDonationPercent > 100) oengusRenderer.goalDonationPercent = 100;

        const minX = oengusRenderer.goalDonationLeftDefault;
        const toAdd = 4;
        let currentLeft = minX * oengusRenderer.goalDonationPercent / 100;
        currentLeft -= toAdd;
        oengusRenderer.goalDonationLeft = minX - currentLeft;
        if (oengusRenderer.shownDonationGoalProgressBar) {
            oengusRenderer.animateDonationGoalProgressBar();
        }
    },

    getGoalAnimationDuration: () => {
        return (oengusRenderer.goalDonationPercent - oengusRenderer.oldGoalDonationPercent) * 50;
    },

    enableUpdatingDonationGoal: () => {
        oengusRenderer.shouldUpdateDonationGoal = true;
        oengusRenderer.maybeUpdateDonationGoal();
    },

    disenableUpdatingDonationGoal: () => {
        oengusRenderer.shouldUpdateDonationGoal = false;
    },

    resetDonationGoalPosition: () => {
        // console.log("Resetting goal bar position");
        oengusRenderer.shownDonationGoalProgressBar = false;
        oengusRenderer.goalDonationPercent = 0;
        oengusRenderer.oldGoalDonationPercent = 0;
        oengusRenderer.goalDonationPercentCounter = 0;
        document.querySelector('#donation-progress .donation-bar-progressbar').style.left = oengusRenderer.goalDonationLeftDefault + 'px';
    },

    maybeResetDonationGoalPosition: () => {
        if (oengusRenderer.shouldResetDonationGoalPosition) {
            oengusRenderer.resetDonationGoalPosition();
            oengusRenderer.shouldResetDonationGoalPosition = false;
        }
    },

    animateDonationGoalProgressBar: () => {
        if (oengusRenderer.shouldResetDonationGoalPosition) return;
        if (oengusRenderer.isAnimatingDonationGoalProgressBar) return;
        if (oengusRenderer.goalDonationPercent === oengusRenderer.oldGoalDonationPercent) return;

        oengusRenderer.isAnimatingDonationGoalProgressBar = true;
        const flairPercentEl = document.querySelector('#donation-progress .donation-bar-progressbar .percent');
        const currentGoalDonationPercent = oengusRenderer.goalDonationPercent;
        anime({
            targets: oengusRenderer,
            goalDonationPercentCounter: oengusRenderer.goalDonationPercent,
            round: 10,
            easing: 'easeOutCirc',
            duration: oengusRenderer.getGoalAnimationDuration(),
            delay: 1000,
            update: function () {
                flairPercentEl.innerHTML = parseInt(oengusRenderer.goalDonationPercentCounter) + '%';
            },
            complete: function () {
                oengusRenderer.shownDonationGoalProgressBar = true;
                oengusRenderer.isAnimatingDonationGoalProgressBar = false;
                oengusRenderer.oldGoalDonationPercent = currentGoalDonationPercent;
            }
        });
        anime({
            targets: '#donation-progress .donation-bar-progressbar',
            left: oengusRenderer.goalDonationLeft,
            easing: 'easeOutCirc',
            duration: oengusRenderer.getGoalAnimationDuration(),
            delay: 1000,
            complete: function () {
                if (!strapi.getDonationDeploySurprise()) return;
                if (!oengusRenderer.shouldUpdateDonationGoal) return;
                if (oengusRenderer.surpriseDeployed) return;
                if (oengusRenderer.goalDonationPercent >= 100) {
                    window.confetti.default({
                        particleCount: 200,
                        spread: 200,
                        origin: { y: 0.2, x: 0.6 },
                        zIndex: 50,
                    });
                    window.confetti.default({
                        particleCount: 200,
                        spread: 150,
                        origin: { y: 0.2, x: 0.4 },
                        zIndex: 50,
                    });
                    window.confetti.default({
                        particleCount: 200,
                        spread: 140,
                        origin: { y: 0.2 },
                        zIndex: 50,
                    });
                    oengusRenderer.surpriseDeployed = true;
                }
            }
        });
    },

    showDonationGoalProgressBar: () => {
        oengusRenderer.enableUpdatingDonationGoal();

        const donationGoalDuration = 10000;
        const donationGoalEnterAnimationDuration = 500;
        anime({
            targets: '#donation-progress',
            duration: donationGoalEnterAnimationDuration,
            opacity: 1,
            direction: 'alternate',
            easing: 'easeInOutExpo',
            endDelay: donationGoalDuration,
        });
        anime({
            targets: ['#donation-progress .donation-flair', '#donation-progress .donation-totals'],
            duration: donationGoalEnterAnimationDuration,
            top: 4,
            direction: 'alternate',
            easing: 'easeInOutExpo',
            endDelay: donationGoalDuration,
        });
        anime({
            targets: ['#donation-progress .donation-bar-container'],
            duration: donationGoalEnterAnimationDuration,
            bottom: 10,
            direction: 'alternate',
            easing: 'easeInOutExpo',
            endDelay: donationGoalDuration,
            complete: function () {
                oengusRenderer.shouldResetDonationGoalPosition = true;
                oengusRenderer.disenableUpdatingDonationGoal();

                if (window.stopLoop)
                    return;

                flagRenderer.showRed();
            }
        });
        oengusRenderer.animateDonationGoalProgressBar();
    },

    shownLeaderboard: false,

    maybeShowLeaderboard: () => {
        const leaderboardElement = document.querySelector('#donation-leaderboards');
        if (strapi.shouldShowLeaderboard()) {
            leaderboardElement.classList.remove('hidden');
            if (oengusRenderer.shownLeaderboard)
                return;

            anime({
                targets: leaderboardElement,
                duration: 5000,
                opacity: 1,
                easing: 'easeInOutExpo',
            });
        }
        else
            leaderboardElement.classList.add('hidden');
    },

    shownBreakDonationContainer: false,

    showBreakDonationContainer: () => {
        if (oengusRenderer.shownBreakDonationContainer)
            return;

        oengusRenderer.shownBreakDonationContainer = true;
        anime({
            targets: '#break-donations .donation-container',
            opacity: 1,
            easing: 'easeInOutQuad',
            duration: 1500,
            delay: 3000,
        });
    },

    updateDonationLeaderboard: () => {
        const donationLeaderboard = strapi.getDonationLeaderboard();
        donationLeaderboard.forEach((d, i) => {
            const selector = `#break-donations .donation-leaderboard .leaderboard-${i + 1}`;
            const donationLeaderboardElement = document.querySelector(selector);
            donationLeaderboardElement.querySelector(".name").innerHTML = d.name;
            const formattedAmount = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(d.total);
            donationLeaderboardElement.querySelector(".amount").innerHTML = formattedAmount;
        })
    },

    startDonationLeaderboardToggleLoop: () => {
        const crossFadeDelay = 30000;
        const crossFadeDuration = 1500;
        anime({
            targets: '#break-donations .donation-leaderboard',
            opacity: 1,
            easing: 'easeInOutQuad',
            duration: crossFadeDuration,
            delay: crossFadeDelay / 2,
            endDelay: crossFadeDelay / 2,
            direction: 'alternate',
            loop: true,
        });
        anime({
            targets: '#break-donations .donator-leaderboard',
            opacity: 0,
            easing: 'easeInOutQuad',
            duration: crossFadeDuration,
            delay: crossFadeDelay / 2,
            endDelay: crossFadeDelay / 2,
            direction: 'alternate',
            loop: true,
        });
    },

    startBreakIncentiveLoop: () => {
        let i = 0;
        strapi.incentives.forEach(incentive => {
            setTimeout(function () {
                oengusRenderer.populateAndShowBreakIncentive(incentive);
            }, i * (oengusRenderer.breakIncentivesShowDuration + 1500));
            i++;
        });
        setTimeout(function () {
            oengusRenderer.showAdBreak(oengusRenderer.currentAdBreakIndex);
        }, (strapi?.incentives?.length ?? 0) * (oengusRenderer.breakIncentivesShowDuration + 1500))
    },

    currentAdBreakIndex: 0,

    showAdBreak: (adIndex) => {
        let adUrl = strapi.getBreakScreenAds()[adIndex] ?? '';
        if (adUrl === '') {
            oengusRenderer.startBreakIncentiveLoop();
            return;
        }

        adUrl = strapi.assetsUrl + adUrl;

        const adImage = document.querySelector('.ad-container');
        adImage.style.backgroundImage = `url(${adUrl})`;
        anime({
            targets: '.ad-container',
            duration: 1000,
            easing: 'linear',
            opacity: [
                { value: 1 },
                { value: 0, delay: oengusRenderer.breakIncentivesShowDuration }
            ],
            complete: function () {
                oengusRenderer.currentAdBreakIndex++;
                if (oengusRenderer.currentAdBreakIndex >= strapi.getBreakScreenAds().length)
                    oengusRenderer.currentAdBreakIndex = 0;
                oengusRenderer.startBreakIncentiveLoop();
            }
        });
    },

    populateAndShowBreakIncentive: (incentive) => {
        anime({
            targets: '.incentive-break .incentive-container',
            duration: 1000,
            easing: 'linear',
            opacity: [
                { value: 1 },
                { value: 0, delay: oengusRenderer.breakIncentivesShowDuration }
            ]
        });
        oengusRenderer.populateBreakIncentiveGameData(incentive);
        if (incentive.type === 'fixed')
            oengusRenderer.populateBreakFixedIncentiveData(incentive);
        else if (incentive.type === 'option')
            oengusRenderer.populateBreakOptionIncentiveData(incentive);
    },

    populateBreakIncentiveGameData: (incentive) => {
        const titleContainerElement = document.querySelector('.incentive-break .incentive-container .incentive-title');
        const gameNameElement = titleContainerElement.querySelector('.data .game-name');
        const descriptionElement = titleContainerElement.querySelector('.data .description');

        gameNameElement.innerHTML = incentive.gameName;
        descriptionElement.innerHTML = incentive.name;

        const fixedContainerElement = document.querySelector('.incentive-break .incentive-container .incentive-break-data .incentive-fixed');
        const optionContainerElement = document.querySelector('.incentive-break .incentive-container .incentive-break-data .incentive-bars');
        if (incentive.type === 'fixed') {
            fixedContainerElement.style.display = 'flex';
            optionContainerElement.style.display = 'none';
        } else {
            fixedContainerElement.style.display = 'none';
            optionContainerElement.style.display = 'flex';
        }
    },

    populateBreakFixedIncentiveData: (incentive) => {
        const containerElement = document.querySelector('.incentive-break .incentive-container .incentive-break-data .incentive-fixed');
        const innerCircle = containerElement.querySelector('.circle.inner svg path');
        const percentElement = containerElement.querySelector('.totals .percent');
        const totalElement = containerElement.querySelector('.totals .total');

        let formattedTotal = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(incentive.total);
        let goalTotal = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(incentive.goal);

        totalElement.innerHTML = formattedTotal + ' / ' + goalTotal;
        const percent = parseInt(incentive.total * 100 / incentive.goal);
        percentElement.innerHTML = percent + '%';
        innerCircle.style.strokeDasharray = percent + ', 100';
    },

    populateBreakOptionIncentiveData: (incentive) => {
        const containerElement = document.querySelector('.incentive-break .incentive-container .incentive-break-data .incentive-bars');
        const bar1Element = containerElement.querySelector('.bar-1');
        const bar2Element = containerElement.querySelector('.bar-2');
        const bar3Element = containerElement.querySelector('.bar-3');

        const bar1Total = bar1Element.querySelector('.total');
        const bar2Total = bar2Element.querySelector('.total');
        const bar3Total = bar3Element.querySelector('.total');

        const bar1Option = bar1Element.querySelector('.name');
        const bar2Option = bar2Element.querySelector('.name');
        const bar3Option = bar3Element.querySelector('.name');

        const firstTotal = incentive.options[0]?.total ?? 0;
        const secondTotal = incentive.options[1]?.total ?? 0;
        const thirdTotal = incentive.options[2]?.total ?? 0;
        const hasAllZero = firstTotal === 0 && secondTotal === 0 && thirdTotal === 0;

        const hasOption1 = incentive.options[0] !== undefined;
        const hasOption2 = incentive.options[1] !== undefined;
        const hasOption3 = incentive.options[2] !== undefined;

        bar1Element.style.display = 'flex';
        bar2Element.style.display = 'flex';
        bar3Element.style.display = 'flex';

        if (hasOption1) {
            bar1Total.innerHTML = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(firstTotal);
            bar1Option.innerHTML = incentive.options[0].name;
        } else {
            bar1Element.style.display = 'none';
        }
        if (hasOption2) {
            bar2Total.innerHTML = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(secondTotal);
            bar2Option.innerHTML = incentive.options[1].name;
        } else {
            bar2Element.style.display = 'none';
        }
        if (hasOption3) {
            bar3Total.innerHTML = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(thirdTotal);
            bar3Option.innerHTML = incentive.options[2].name;
        } else {
            bar3Element.style.display = 'none';
        }

        if (hasOption1 && hasOption2) {
            if (hasAllZero) {
                bar2Element.style.marginLeft = 0;
                bar3Element.style.marginLeft = 0;
            } else {
                bar2Element.style.marginLeft = - (390 - (390 * (secondTotal - thirdTotal) / (firstTotal - thirdTotal)));
                bar3Element.style.marginLeft = -390;
            }
        }

        const noOptionElement = containerElement.querySelector('.no-options');
        if (!hasOption1) {
            noOptionElement.style.display = 'flex';
        } else {
            noOptionElement.style.display = 'none';
        }
    },
};

strapi.callWhenReady(function () {
    oengusRenderer.fadeInOutRunnerName(1);
    oengusRenderer.fadeInOutRunnerName(2);
    setTimeout(oengusRenderer.startFizzleWatcher, 5000);
    oengusRenderer.startDonationLeaderboardToggleLoop();
    oengusRenderer.showBreakDonationContainer();
});
strapi.callWhenUpdated(oengusRenderer.updateDonations);
strapi.callWhenUpdated(oengusRenderer.updateDonatorsLeaderboard);
strapi.callWhenUpdated(oengusRenderer.updateDonationLeaderboard);
strapi.callWhenUpdated(oengusRenderer.updateDonationReason);
strapi.callWhenUpdated(oengusRenderer.maybeUpdateDonationGoal);
strapi.callWhenUpdated(oengusRenderer.updateHostName);
strapi.callWhenUpdated(oengusRenderer.updateBreakScreenHost);
strapi.callWhenUpdated(oengusRenderer.maybeShowLeaderboard);

// Da aggiornare una volta sola
strapi.callWhenUpdated(oengusRenderer.updateRunnerName);
strapi.callWhenUpdated(oengusRenderer.updateCurrentRunInfo);
strapi.callWhenUpdated(oengusRenderer.updateCouch);


oengus.callWhenRefreshed(oengusRenderer.updateAndShowAllBreakRuns);

window.oengusRenderer = oengusRenderer;
window.anime = anime;